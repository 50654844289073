import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'webcrypto-shim';
import 'polyfill-array-includes';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'astm-ux-design-system/dist/bundle.css';
import "react-datepicker/dist/react-datepicker.css"
import 'react-datepicker/dist/react-datepicker-cssmodules.min.css'
import 'react-widgets/dist/css/react-widgets.css';
import './assets/themes/main.css';
import App from './components/pages/app';
import { BrowserRoutesProvider, OIDC, loginHandler, verifyMECallForSession, getAuthHeaderToken } from './helpers/OktaUtility';
import './assets/css/app.css';
import * as serviceWorker from './serviceWorker';
import store from './store';
import { BannerProvider } from './providers/banner';
import { BrowserRouter as Router } from 'react-router-dom';

(() => {
  let isJiraEnabled = process.env.REACT_APP_JIRA_ISSUE_COLLECTOR_ENABLED;
  if (isJiraEnabled === 'true') {
    let src = process.env.REACT_APP_JIRA_ISSUE_COLLECTOR_URL;
    // eslint-disable-next-line no-useless-escape
    document.write('<script src="' + src + '"><\/script>');
  }
})();

// TODO : set up required props.
const OKTA_PROPS = {
  OIDC,
  loginHandler,
  verifyMECallForSession,
  getAuthHeaderToken
}


createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <Router basename='/'>
      <BrowserRoutesProvider OIDC_CONFIG={OIDC}>
        <BannerProvider>
          <App {...OKTA_PROPS} />
        </BannerProvider>
      </BrowserRoutesProvider>
    </Router>
  </Provider>
);
serviceWorker.unregister();