import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security } from '@okta/okta-react';
import { get } from 'lodash';
import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { setMessage } from '../redux/shared/actions';
import store from '../store';
import { clearSession, getUserInfo, removeAppCookies, setRedirectUrl } from './utilCommon';


// Flag For child application.
export const IS_CHILD_APP = true;
export let PROTACTED_HOME_PAGE = '/app';

// Setup Configuration for OktaAuth. 
export const OIDC = new OktaAuth({
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true, // [true] provides a modern solution for protecting SPAs. 
    postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_URI,
    disableHttpsCheck: window.location.hostname !== 'localhost' ? true : false, // [True] if working on https environment.
    tokenManager: {
        expireEarlySeconds: 60,
        autoRenew: true, // autorenew [true] auto update access token
        secure: window.location.hostname !== 'localhost' ? true : false, // This option is only relevant if storage is set to cookie and false option is only for testing
        //storage: 'localStorage' // localStorage/sessionStorage/cookie,
    }
});

// TODO : get ID token from okta auth
export const getIdToken = (oktaAuth) => {
    return oktaAuth.tokenManager.get('id_token');
};

// Callback method OKAT Auth handler.
export const authHandler = async (authService) => {
    const hashValue = window.location.hash;
    let defaultUrl = '/';
    // Check hash available in the URL.
    if (hashValue) {
        let sessionToken = window.location.hash.split('#')[1];
        window.sessionStorage.setItem('referrerPath', defaultUrl ? defaultUrl : PROTACTED_HOME_PAGE);
        OIDC.token.getWithRedirect({
            scopes: OIDC.options.scopes,
            sessionToken
        });
    } else {
        const isSessionExist = await authService.session.exists();
        if (isSessionExist) {
            let token = await getIdToken(authService);
            // TODO : check if token exist.
            if (!token) {
                authService.token.getWithRedirect({
                    scopes: OIDC.options.scopes
                });
            }
        } else {
            clearSession();
            removeAppCookies();
            if (window.location.pathname) {
                setRedirectUrl(window.location.pathname);
            }
            // TODO : Go and will request for login page
            let loginUrl = process.env.REACT_APP_LOGIN_PAGE_REQUEST + window.btoa(window.location.origin);
            // TODO : Check current app is child app.
            if (IS_CHILD_APP === true) {
                window.location.assign(process.env.REACT_APP_LOGOUT_URI);
            } else {
                // TODO : Set value in LS to track
                window.location.assign(loginUrl);
            }
        }
    }
}

// Logout Handler
export const logoutHandler = async () => {
    try {
        OIDC.signOut('/');
    } catch (error) {
        console.error(error);
    }
}


// TODO : Login handler.
export const loginHandler = () => {
    authHandler(OIDC);
}

//TODO : set auth token header.
export const getAuthHeaderToken = async () => {
    const tokenInfo = await OIDC.tokenManager.get('accessToken');
    // To check token is valid.
    if (tokenInfo) {
        if (OIDC.tokenManager.hasExpired(tokenInfo)) {
            const renewAccessToken = await OIDC.token.renew(tokenInfo);
            return renewAccessToken ? renewAccessToken.accessToken : null;
        } else {
            return tokenInfo.accessToken;
        }
    }
    return null;
}

// TODO : Check ME call on Routes Change
export const verifyMECallForSession = () => {
    const userInfo = getUserInfo();
    if (userInfo && get(OIDC, 'session')) {
        OIDC.session.get().then(async sessionInfo => {
            const tokenInfo = await getAuthHeaderToken();
            // Clear Cookies if cross tab along with [expired to new session] will be apply.
            if (!sessionInfo) {
                // Forcefully remove storages if no session exists.
                store.dispatch(setMessage(true, '401'));
                clearSession();
                removeAppCookies();
                loginHandler();
            } else if (userInfo.email && userInfo.email !== sessionInfo.login) {
                clearSession();
                removeAppCookies();
                loginHandler();
            } else if (sessionInfo && !tokenInfo) {
                // Remove garbage storage if already exists.
                clearSession();
                removeAppCookies();
                loginHandler();
            }
        })
    }
}

// TODO : Render security component from common place.
export const BrowserRoutesProvider = ({ OIDC_CONFIG, ...props }) => {
    const history = useHistory()
    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
    };
    return (
        <Security oktaAuth={OIDC_CONFIG} onAuthRequired={authHandler} restoreOriginalUri={restoreOriginalUri}>
            <Route path="/auth" component={LoginCallback} />
            {props.children}
        </Security>
    );
}

export default {
    OIDC,
    BrowserRoutesProvider,
    authHandler,
    getAuthHeaderToken,
    loginHandler,
    verifyMECallForSession
}
